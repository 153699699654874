import React from "react";
import { Button } from "react-bootstrap";
import {Link} from "react-router-dom";

const ComFormulir = (props) => {
  return (
    <tr>
    <td>{props.data.keterangan} </td>
    <td className="text-center">
      <a rel="noopener noreferrer" target="_blank" href={props.data.nfile} ><i className="fe fe-download"/> Download</a>
    </td>
  </tr>
  );
};

export default ComFormulir;
