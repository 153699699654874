import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ComService = () => {
  return (
    <div>
      <div className="row row-sm">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <h5 className="text-primary tx-center mt-2 pb-3">
            Silahkan Pilih Layanan di bawah ini
          </h5>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_kk">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/kk.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">PENGAJUAN KK</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/konsolidasi">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/kk.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">PENGADUAN/KONSOLIDASI</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_perpindahan">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/perubahan.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2"> PERPINDAHAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_kedatangan">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/kedatangan.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2"> KEDATANGAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_perubahandata">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/perubahan.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2"> PERUBAHAN DATA</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_cetakkia">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/cetakkia.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">CETAK KIA</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_aktekelahiran">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/aktekelahiran.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">AKTE KELAHIRAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_aktekematian">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/aktekematian.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">AKTE KEMATIAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_akteperkawinan">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/aktenikah.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">AKTE PERKAWINAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_akteperceraian">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/akteperceraian.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">AKTE PERCERAIAN</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6">
          <Link to="/pengajuan_aktehilang">
          <div className="card">
            <div className="card-body bg-services text-center">
              <div className="bg-white mb-0 rounded-circle text-center icon-service">      
                <img alt="icon"   src={"assets/img/icon/hilangrusak.png"} style={{width:'100px'}}/>               
              </div>
              <h5 className="text-white text-uppercase mt-2">AKTE HILANG/RUSAK</h5>
            </div>
          </div>
          </Link>
        </div>
       
       
        
      </div>
    </div>
  );
};

export default ComService;
