import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import $ from "jquery";



class MenuAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionToken:localStorage.getItem("login_public"),
      out:false,
    };
  }

  //logout
  logout = () => {
    this.setState({
      sessionToken: "",
      out:true,
    });
    localStorage.clear();
  };

  componentDidMount() {
    //toogle
    $(".horizontal-navtoggle").on("click", function () {
      $("body").toggleClass("active");
    });
    $(".horizontal-overlapbg").on("click", function () {
      $("body").removeClass("active");
    });
    //seacrh
    var res = $(".navbar-form").find(":reset");
    res.on("click", function () {
      $(".navbar-form").removeClass("active");
    });
  }
  render() {
    if(this.state.out === true){
      return <Redirect to="/login" />
    }
    return (
      <div>
        <div className="sticky mt-20">
          <div className="horizontal-main hor-menu clearfix side-header">
            <div className="horizontal-mainwrapper container clearfix">
              <nav className="horizontalMenu clearfix">
                <ul className="horizontalMenu-list">
                  <li >
                    <LinkContainer to="/" exact title="Home">
                      <Nav.Link>
                        <i className="fe fe-home" /> Home
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/pelayanan" exact title="Pelayanan">
                      <Nav.Link>
                        <i className="fe fe-airplay" /> Pelayanan
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/anggota" exact title="Anggota Keluarga">
                      <Nav.Link>
                        <i className="fe fe-users" /> Anggota Keluarga
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="#" exact title="Cetak Dokumen">
                      <Nav.Link>
                      <i className="fe fe-printer" /> Cetak Dokumen
                      <i className="fe fe-chevron-down horizontal-icon" />
                      </Nav.Link>
                    </LinkContainer>
                    
                    <ul className="sub-menu">
                      <li >
                        <LinkContainer to="/dokumentte" exact title="Cetak KK">
                          <Nav.Link>Cetak KK</Nav.Link>
                        </LinkContainer>
                      </li>
                      <li >
                        <LinkContainer to="/dokumenpindah" exact title="Cetak KK Pindah">
                          <Nav.Link>Cetak KK Pindah</Nav.Link>
                        </LinkContainer>
                      </li>
                      <li >
                        <LinkContainer to="/dokumenakte" exact title="Cetak Akte">
                          <Nav.Link>Cetak Akte</Nav.Link>
                        </LinkContainer>
                      </li>
                    </ul>
                  </li>
                  <li >
                    <LinkContainer to="/pengajuan" exact title="Pengajuan">
                      <Nav.Link>
                        <i className="fe fe-monitor" /> Pengajuan
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/persyaratan" exact title="Persyaratan">
                      <Nav.Link>
                        <i className="fe fe-list" /> Persyaratan
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/formulir" exact title="Formulir">
                      <Nav.Link>
                        <i className="fe fe-download" /> Formulir
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li className="menu_aktivasi">
                    <LinkContainer to="#" exact title="Aktivasi">
                      <Nav.Link>
                        <i className="fe fe-user-check" /> Aktivasi
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li className="menu_lupa_password">
                    <LinkContainer to="#" exact title="Lupa Password">
                      <Nav.Link>
                        <i className="fe fe-lock" /> Lupa Password
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                   <LinkContainer to="#" exact title="Formulir" onClick={this.logout}>
                      <Nav.Link>
                      <i className="fe fe-log-out" /> Logout
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuAccount;
