import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import ComAnggota from "./ComAnggota";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";


class PageAnggota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //session Login from local storage
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      datLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0]: null,
      list_anggota: [],
      data_anggota: {},
      errors: false,
    };
  }

  componentDidMount(){
    if (this.state.resLogin === "00") {
      this.dataAnggota();
    }
    const { columns, data, title } = "";
    //initialize datatable
    $(function () {

          setTimeout(function(){
            $('#example').DataTable({
              'columnDefs': [{
                  "targets": [0, -1, -2, -3,-4,-5,-6,-7,-8,-11], //last column
                  "orderable": false, //set not orderable
              }],
            });
             } ,1000);
    });

  }

  dataAnggota = () => {
    fetch("lumen7/public/anggota", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: "ttdatakeluarga",
        pwd: "12345",
        //no_kk: this.state.datLogin.no_kk,
        nik: this.state.datLogin.nik,
		    kodesbv: this.state.datLogin.kdd,
        kodedd: this.state.datLogin.kkode
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.kode === "00") {
          this.setState({
            list_anggota: data.data,
            data_anggota: JSON.stringify(data.data),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  render() {
    if (this.state.resLogin !== "00") {
      return <Redirect to="/login" />;
    }
    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Daftar Anggota Keluarga
                </li>
              </ol>
            </nav>
          </div>
          {this.state.resLogin==='00' ? (
            <div className="d-flex">
              <div className="d-flex right-page">
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NAMA LENGKAP</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].nama}
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NIK</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].nik}
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label">NO KK</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].no_kk}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm mt-20">
            <div className="col-md-12 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Anggota Keluarga
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table id="example" className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th width="30px">NO</th>
                          <th>NIK</th>
                          <th>NAMA</th>
                          <th>KABUPATEN</th>
                          <th>ALAMAT</th>
                          <th>TGL LAHIR</th>
                          <th>PENDIDIKAN</th>
                          <th>PEKERJAAN</th>
                          <th>STATUS NIKAH</th>
                          <th>JENIS KELAMIN</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.errors===false ? (
                          this.state.list_anggota.map((data_anggota,i) => {
                            return (
                              <ComAnggota
                                key={data_anggota.stat_hbkel}
                                nom={i+1}
                                data={data_anggota}
                              />
                            );
                          })
                        ):(
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageAnggota;
