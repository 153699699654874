import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import $ from "jquery";
import ComPersyaratanstatis from "./ComPersyaratanstatis";



const ComPersyaratan = (props) => {
  var result1=props.data.persyaratan;
  var result=result1.split('--');
  const products = ['orange', 'apple', 'watermelon'];

  const list = []

 
  for (var xx = 0; xx < props.data.jml; xx++) {
    list.push(<li>{result[xx]}</li>)
  }
  return (
    <div>
   
       
     
        <div className="card">
        <div className="card-header" id="headingOne" role="tab">
          <a
            aria-controls={"datake"+props.data.id}
            aria-expanded="true"
            data-toggle="collapse"
            href={"#datake"+props.data.id}
          >
           {props.data.nama}
          </a>
        </div>
        <div
          aria-labelledby="headingOne"
          className="collapse"
          data-parent="#accordion"
          id={"datake"+props.data.id}
          role="tabpanel"
        >
          <div className="card-body">
            <ol>
         {list}
              
            </ol>
          </div>
        </div>
      </div>
            
           

   
       

     

     
    </div>
  );
};

export default ComPersyaratan;
