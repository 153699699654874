import React, { Component } from "react";
import { Link } from "react-router-dom";
//import ComDaftar from "./ComDaftar";
import Main from "../../layout/Main";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageVerifikasi extends Component {
   constructor(props) {
    super(props);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const foo = params.get('kode');
	 this.state = {
       notif:null,
	  
    };
	  fetch("lumen7/public/verifikasiakun", {
		  method: "POST",
		  mode: "cors",
		  body: JSON.stringify({
			kode: foo

		  }),
		  headers: new Headers({
			"content-type": "application/json",
			"Authorization":"verifikasiakun"
		  }),
		})
		.then((response) => response.json())
      .then((data) => {
         this.setState({
            notif: true
          });
        //loading
       
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
	  
      
  }
  notif = () => {
    if(this.state.notif === true){
      return(<div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>{this.state.notif2}</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else if(this.state.notif === false){
      return(<div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Pendaftaran Gagal !</strong> {this.state.notif2}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else{
      <></>
    }
    
  };
   
  render() {
    return (
      <Main>
        <div>
          <div className="breadcrumb-header justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Verifikasi  Akun
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="main-content-body min-body">
            <div className="row row-sm mt-20">
              <div className="col-md-6 offset-md-3 mg-md-t-0">
                <div className="card">
                  <div className="card-body">
                    <div className="main-content-label mg-b-5">
                      Form Pendaftaran Akun
                    </div>
                    <p className="mg-b-20">
                     Akun berhasil di verifikasi  {this.state.notif}
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageVerifikasi;
