import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import ComPengajuandata from "./ComPengajuandata";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PagePengajuan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //session Login from local storage
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      datLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0]: null,
      list_anggota: [],
      data_anggota: {},
      errors: false,
      isEdit: false,
      dataEdit: {},
      urlEdit:null,
      urlId:null
    };
  }

  componentDidMount() {
    if (this.state.resLogin === "00") {
      this.dataAnggota();
    }
  }

  dataAnggota = () => {
    fetch("lumen7/public/datapengajuan", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: "ttdatakeluarga",
        pwd: "12345",
        nik: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
        kodesbv: JSON.parse(localStorage.getItem("login_public")).data[0].kdd,
        kodedd: JSON.parse(localStorage.getItem("login_public")).data[0].kkode,
        no_kk: JSON.parse(localStorage.getItem("login_public")).data[0].no_kk,
        nik1: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
      }),
      headers: new Headers({
        "content-type": "application/json",
        beta: JSON.parse(localStorage.getItem("login_public")).data[0].no_kk,
        betank: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            list_anggota: data.data,
            data_anggota: JSON.stringify(data.data),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  handleEdit = (props) => {
    this.setState({
      isEdit: true,
      dataEdit: props,
      urlEdit:props.data.url,
      urlId:props.data.id
    });
  };

  render() {
    if (this.state.resLogin !== "00") {
      return <Redirect to="/login" />;
    }

    if (this.state.urlEdit) {
      return <Redirect to={{
        pathname: "/"+this.state.urlEdit+"",
        search: "?ui=1&kode="+this.state.urlId+"",
        state: { dataEdit: this.state.urlEdit }
      }}/>
    }

    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Daftar Anggota Keluarga
                </li>
              </ol>
            </nav>
          </div>
          {this.state.resLogin === "00" ? (
            <div className="d-flex">
              <div className="d-flex right-page">
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NAMA LENGKAP</span>
                    </span>
                    <span className="value">
                      {
                        JSON.parse(localStorage.getItem("login_public")).data[0]
                          .nama
                      }
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NIK</span>
                    </span>
                    <span className="value">
                      {
                        JSON.parse(localStorage.getItem("login_public")).data[0]
                          .nik
                      }
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label">NO KK</span>
                    </span>
                    <span className="value">
                      {
                        JSON.parse(localStorage.getItem("login_public")).data[0]
                          .no_kk
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm mt-20">
            <div className="col-md-12 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Pengajuan
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th width="30px">NO</th>
                         
                          <th>JENIS</th>
                          <th>PELAYANAN</th>
                          <th>KETERANGAN PEMOHON</th>
                          <th>KETERANGAN ADMIN</th>
                          <th>STATUS</th>
                          <th>File</th>
                          <th>AKSI</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.errors === false ? (
                          this.state.list_anggota.map((data_anggota, i) => {
                            return (
                              <ComPengajuandata
                                key={data_anggota.id}
                                nom={i + 1}
                                data={data_anggota}
                                edit={this.handleEdit}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PagePengajuan;
