import React from "react";
import {Link} from "react-router-dom";
//import { Col, Button, Card } from "react-bootstrap";

const ComPengajuandata = (props) => {

  let isLoggedIn ='1';

  const renderAuthButton = () => {
    if (props.data.nfile.length > 1) {
      return <a href={props.data.nfile} target="_blank">Download </a>;
    } else {
      return "-";
    }
  }

  if (props.data.nstatus <=1) {
    

    return (
      <tr>
        <td className="text-left">{props.nom}</td>
        <td className="text-left">{props.data.jenis}</td>
        <td className="text-left">{props.data.nama}</td>
        <td className="text-left">{props.data.pesan}</td>
        <td className="text-left">{props.data.ketadmin}</td>
        <td className="text-left">{props.data.status}</td>
        <td className="text-left">
        {renderAuthButton()}
        </td>
        <td className="text-left">
        <Link to="#" onClick={() => props.edit(props)}>edit</Link>
          </td>
      
      </tr>
    );
  }else{
    return (
      <tr>
        <td className="text-left">{props.nom}</td>
        <td className="text-left">{props.data.jenis}</td>
        <td className="text-left">{props.data.nama}</td>
        <td className="text-left">{props.data.pesan}</td>
        <td className="text-left">{props.data.ketadmin}</td>
        <td className="text-left">{props.data.status}</td>
        <td className="text-left"></td>
        <td className="text-left">
        {renderAuthButton()}
          </td>
      
      </tr>
    );
  }

};

export default ComPengajuandata;
