import React from "react";
//import { Col, Button, Card } from "react-bootstrap";

const ComAnggota = (props) => {
  return (
    <tr>
      <td className="text-left">{props.nom}</td>
      <td className="text-left">{props.data.nik}</td>
      <td className="text-left">{props.data.nama_lgkp}</td>
      <td className="text-left">{props.data.nama_kab}</td>
      <td className="text-left">{props.data.nama_kel}, {props.data.nama_kec}</td>
      <td className="text-left"></td>
      <td className="text-left">{props.data.descrip}</td>
      <td className="text-left">{props.data.namapekerjaan}</td>
      <td className="text-left">{props.data.no_akta_kwn?"Kawin":"Belum Kawin"}</td>
      <td className="text-left"></td>
      <td className="text-left"></td>
    </tr>
  );
};

export default ComAnggota;
