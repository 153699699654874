import React from "react";
import { Button } from "react-bootstrap";
import {Link} from "react-router-dom";

const ComPersyaratanktp = (props) => {
  return (
    <tr>
      <td className="text-left">{props.nom}</td>
      <td className="text-left">
    
        {props.data.persyaratan}
      </td>
      <td className="text-center">
        <a href={props.data.nfile} target="_blank">Download</a>  
        
      </td>
      <td className="text-center">
        <Button  className="btn btn-main-primary btn-sm pd-x-20  mg-r-5 mg-t-5" onClick={() => props.changefile(props,props.data.id)}>
          Pilih
        </Button>
      </td>
     
    </tr>
  );
};

export default ComPersyaratanktp;
