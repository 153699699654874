import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
//import BaseSelect from "react-select";
import { Button, Modal } from "react-bootstrap";
//import * as qs from "query-string";
import $ from "jquery";
import Main from "../../layout/Main";
import ComPersyaratan from "./ComPersyaratan";
import ComPersyaratanktp from "./ComPersyaratanktp";
//import FixRequiredSelect from "./FixRequiredSelect";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

//data wilayah tujuan
const options = [
  { value: "1", label: "Disdukcapil" },
  { value: "29", label: "KECAMATAN CIAMIS" },
  { value: "30", label: "KECAMATAN CIKONENG" },
  { value: "31", label: "KECAMATAN CIJEUNGJING" },
  { value: "32", label: "KECAMATAN SADANANYA" },
  { value: "33", label: "KECAMATAN CIMARAGAS" },
  { value: "34", label: "KECAMATAN PANUMBANGAN" },
  { value: "35", label: "KECAMATAN CIHAURBEUTI" },
  { value: "36", label: "KECAMATAN PANJALU" },
  { value: "37", label: "KECAMATAN KAWALI" },
  { value: "38", label: "KECAMATAN CIPAKU" },
  { value: "39", label: "KECAMATAN PANAWANGAN" },
  { value: "40", label: "KECAMATAN JATINAGARA" },
  { value: "41", label: "KECAMATAN RANCAH" },
  { value: "42", label: "KECAMATAN CISAGA" },
  { value: "43", label: "KECAMATAN TAMBAKSARI" },
  { value: "44", label: "KECAMATAN RAJADESA" },
  { value: "45", label: "KECAMATAN SUKADANA" },
  { value: "46", label: "KECAMATAN BANJARSARI" },
  { value: "47", label: "KEAMATAN LAKBOK" },
  { value: "48", label: "KECAMATAN PAMARICAN" },
  { value: "49", label: "KECAMATAN CIDOLOG" },
  { value: "50", label: "KECAMATAN SINDANGKASIH" },
  { value: "51", label: "KECAMATAN BAREGBEG" },
  { value: "52", label: "KECAMATAN SUKAMANTRI" },
  { value: "53", label: "KECAMATAN LUMBUNG" },
  { value: "54", label: "KECAMATAN PURWADADI" },
  { value: "55", label: "KECAMATAN BANJARANYAR" },
];
// //data fungsi untuk required select2
// const Select = (props) => (
//   <FixRequiredSelect
//     {...props}
//     SelectComponent={BaseSelect}
//     options={props.options || options}
//   />
// );

class FormPengajuankonsolidasi extends Component {
  constructor(props) {
    super(props);
    const foo = window.location.href;
    const dadiurl1 = window.location.href.split("/");
    const dadiurl = window.location.pathname.split("?");
    const foo2 = window.location.href.split("?")[1];

    this.handleFile2 = this.handleFile2.bind(this);
    this.state = {
      //session Login from local storage
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      datLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0]: null,
      //fungsi-fungsi element
      cancel: false,
      show: false,
      loadpengajuan:false,
      loadingAct: false,
      notif: null,
      msg: null,
      errors: false,
      btnsimpan:false,
      //parameter post
      foo2: foo2,
      foo: foo,
      dadiurl: dadiurl,
      dadiurl1: dadiurl1,
      opd: null,
      jenis: null,
      list_anggota: [],
      data_anggota: {},
      updatable: true,
      name: props.name,
      status: props.status,
      fields: {
        id: "",
        nama: "",
        usia: "",
        kabupaten: "",
        kecamatan: "",
        desa: "",
        rt: "",
        rw: "",
        pesan: "",
        pekerjaan: "",
        pendidikan: "",
        jenis:""
      },
      selectedOption: null,
      //getdata
      list_persyaratan: [],
      data_persyaratan: {},
      list_jenis: [],
      data_jenis: {},
      list_persyaratanktp: [],
      data_persyaratanktp: {},
      data_anggotaIndividu:{},
      data_p: {},
      dataopd:[]
    };
  }
  showModal = (e) => {
    var cek=e;
    var id=$('#tablepersyaratan1 #'+cek).closest('tr').attr("cek");
    $('#tablepersyaratan1 tr').removeClass("aktive");
    $('#tablepersyaratan1 #'+cek).closest('tr').addClass("aktive");
  
    this.setState({
      show: true,
    });
  };
  cancelModal = () => {
    this.setState({
      show: false,
    });
  };
  cancelForm = () => {
    this.setState({
      cancel: true,
    });
  };
  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );
  notif = () => {
    if (this.state.notif === true) {
      return (
        <div
          className="alert alert-solid-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>{this.state.msg} !</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
    } else if (this.state.notif === false) {
      return (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>{this.state.msg} !</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
    } else {
      <></>;
    }
  };

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields["nikinput"] = this.state.datLogin.nik;
    this.setState({ fields });
    console.log(this.state.fields);
  };
  handleSelect2 = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields["nikinput"] = this.state.datLogin.nik;
    this.setState({ fields });
    //console.log(this.state.fields);
    this.dataAnggotaIndividu();
  };

  handleFile = (e, props) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
 
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
      document.getElementById("files-b-" + props.data.id).value = "";
    };
    reader.readAsDataURL(file);

    let fields = this.state.fields;
    fields["files-" + props.data.id] = e.target.files[0];
    fields["nikinput"] = this.state.datLogin.nik;
    this.setState({ fields });

    //console.log(file);
  };
  handleFile2(props, file) {
    // document.getElementById("files-b-" + props.data.id).value = file;
    // document.getElementById("files-" + props.data.id).value = "";
   
    $("#tablepersyaratan1 tbody .aktive .11").val(props.data.id);
    $("#tablepersyaratan1 tbody .aktive .12").attr('href',props.data.nfile);
    $("#tablepersyaratan1 tbody .aktive .12").html('Lihat');
    this.cancelModal();
    //console.log(props.data.id);
  }
  handleSelect1 = (opd) => {
    this.setState({ opd });
    let fields = this.state.fields;
    fields["opd"] = opd.value;
    fields["nikinput"] = this.state.datLogin.nik;
    this.setState({ fields });
    //console.log(this.state.fields);
  };

  handleSubmit = (e) => {
    this.setState({
      btnsimpan:true,
      loadingAct: false,
      notif: false,
      msg:""
    });
    fetch("lumen7/public/simpanperubahan", {
      method: "POST",
      mode: "cors",
      body: new FormData(e.target),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            loadingAct: true,
            notif: true,
           
            msg: data.pesan,
          });
          //loading
          setTimeout(() => {
            this.loadingAction();
            this.setState({
              loadpengajuan:true,
              notif: false,
              loadingAct: false,
              msg: data.pesan,
            });
            //reset form
            document.getElementById("FormPengajuanAkteKelahiran").reset();
            this.notif();
          }, 2000);
        } else {
          this.setState({
            loadingAct: false,
            notif: true,
            msg: data.pesan,
            btnsimpan:false
          });
          //loading
          /*    setTimeout(() => {
            this.loadingAction();
            this.setState({
              msg: null,
            });
              
          }, 1000); */
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    e.preventDefault();
  };

  listPersyaratan = () => {
    fetch("lumen7/public/persyaratanjenis", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        id: this.state.dadiurl1[3],
        jns_id: this.state.dadiurl[0],
        kodesbv: JSON.parse(localStorage.getItem("login_public")).data[0].kdd,
        kodedd: JSON.parse(localStorage.getItem("login_public")).data[0].kkode,
        no_kk: JSON.parse(localStorage.getItem("login_public")).data[0].no_kk,
        nik1: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            list_persyaratan: data.data,
            data_persyaratan: JSON.stringify(data.data),
          });
        }
        if (data.kode2 === "00") {
          this.setState({
             list_persyaratanktp: data.dataktp,
            data_persyaratanktp: JSON.stringify(data.dataktp),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  list_p = () => {
    fetch("lumen7/public/perubahanform", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: this.state.foo,
        jns_id: "pengajuan_perubahandata",
        kodesbv: JSON.parse(localStorage.getItem("login_public")).data[0].kdd,
        kodedd: JSON.parse(localStorage.getItem("login_public")).data[0].kkode,
        no_kk: JSON.parse(localStorage.getItem("login_public")).data[0].no_kk,
        nik1: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            fields: data.data[0],
            selectedOption: data.data[0].nik,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  dataAnggotaIndividu = () => {
    fetch("lumen7/public/anggotaindividu", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: "ttdatakeluarga",
        pwd: "12345",
        nik: this.state.fields.opd,
        no_kk: JSON.parse(localStorage.getItem("login_public")).data[0].no_kk,
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          
          this.setState({
            data_anggotaIndividu: data.data[0],
          });
          //console.log(this.state.data_anggotaIndividu);
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  dataAnggota = () => {
    fetch("lumen7/public/anggota", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: "ttdatakeluarga",
        pwd: "12345",
        nik: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
        kodesbv: JSON.parse(localStorage.getItem("login_public")).data[0].kdd,
        kodedd: JSON.parse(localStorage.getItem("login_public")).data[0].kkode,
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
          
            list_anggota: data.data,
            data_anggota: JSON.stringify(data.data),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  datajenis = () => {
    fetch("lumen7/public/jenispengaduan", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: "ttdatakeluarga",
        pwd: "12345",
        nik: JSON.parse(localStorage.getItem("login_public")).data[0].nik,
        kodesbv: JSON.parse(localStorage.getItem("login_public")).data[0].kdd,
        kodedd: JSON.parse(localStorage.getItem("login_public")).data[0].kkode,
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
          
            list_jenis: data.data,
            data_jenis: JSON.stringify(data.data),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount() {
    if (this.state.resLogin === "00") {
      this.dataAnggota();
      this.listPersyaratan();
      this.datajenis();
    }

    if (typeof window.location.href.split("?")[1] !== 'undefined'){
      this.list_p();
    }
  }
  onTodoChange(value){
    this.setState({
         name: value
    });
  }
  render() {
    const { resLogin, cancel,loadpengajuan } = this.state;
    const selected1='';
    let download='';
    let htmlbtn='Simpan';
    let dsbled='';
  
    if (this.state.btnsimpan === true) {
      htmlbtn="...Loading";
      dsbled="disabled";
    }

    if (resLogin !== "00") {
      return <Redirect to="/login" />;
    }
    if (cancel) {
      return <Redirect to="/pelayanan" />;
    }
    if (loadpengajuan) {
      return <Redirect to="/pengajuan" />;
    }
    if (typeof window.location.href.split("?")[1] !== 'undefined'){
      download ='<th className="tx-center">Download</th>';
    }else{
       download="";
    }
   if (this.state.dadiurl[0]=='/konsolidasi'){
   }
    // const options_opd=this.state.options.map(
    //   (dataopd,i){
    //     return {value:dataopd['value'],label:dataopd['label']};
    //   }
    // )
    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Form Pengajuan Konsolidasi
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm mt-20">
            <div className="col-md-12 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      FORM PENGAJUAN KONSOLIDASI
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  {this.notif()}
                  <form
                    onSubmit={this.handleSubmit}
                    className="form-horizontal mb-5 FormPengajuanPerubahanData"
                    id="FormPengajuanPerubahanData"
                  >
                    <input
                      type="hidden"
                      name="kodedd"
                      value={
                        JSON.parse(localStorage.getItem("login_public")).data[0]
                          .kkode
                      }
                    />
                    <input
                      type="hidden"
                      name="kodesbv"
                      value={
                        JSON.parse(localStorage.getItem("login_public")).data[0]
                          .kdd
                      }
                    />
                    <input
                      type="hidden"
                      name="kdd"
                      value={this.state.fields.id}
                    />
                    <input
                      type="hidden"
                      name="jns"
                      value={this.state.dadiurl[0]}
                    />
                    <div className="row row-sm">
                      <div className="col-md-6">
                        <div className="row row-xs align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label text-right mg-b-0">
                              NIK <span className="tx-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mg-t-5 mg-md-t-0">
                            <select
                              className="form-control "
                              name="nik"
                              tuj="dataasal"
                              aria-invalid="false"
                              onChange={this.handleSelect2.bind(this,"opd")}
                            >
                              <option value="1">-</option>
                              <option value={this.state.datLogin.nik}>
                                {this.state.datLogin.nik} -
                                {this.state.datLogin.nama}
                              </option>
                              {this.state.list_anggota.map(
                                (data_anggota, i) => {
                                  if(data_anggota.nik==this.state.fields["nik"]){
                                    return (
                                      <option
                                         key={data_anggota.stat_hbkel}
                                         value={data_anggota.nik}
                                         selected
                                       >
                                         {data_anggota.nik} -
                                         {data_anggota.nama_lgkp}
                                       </option> 
                                     );
                                  }else{
                                    return (
                                      <option
                                         key={data_anggota.stat_hbkel}
                                         value={data_anggota.nik}
                                       >
                                         {data_anggota.nik} -
                                         {data_anggota.nama_lgkp}
                                       </option> 
                                     );

                                  }

                                 

                                  
                                }
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="row row-xs align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label text-right mg-b-0">
                              Jenis <span className="tx-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mg-t-5 mg-md-t-0">
                            <select
                              className="form-control "
                              name="jenis"
                              aria-invalid="false"
                              onChange={this.handleSelect2.bind(this,"jenis")}
                            >
                              <option value="1">-</option>
                            
                              {this.state.list_jenis.map(
                                (data_jenis, i) => {
                                  if(data_jenis.kode==this.state.fields["jenis"]){
                                    return (
                                      <option
                                          value={data_jenis.kode}
                                         selected
                                       >
                                         {data_jenis.nama}
                                       </option> 
                                     );
                                  }else{
                                    return (
                                      <option value={data_jenis.kode} > {data_jenis.nama} </option> 
                                    );

                                  }

                                 

                                  
                                }
                              )}
                            </select>
                          </div>
                        </div>          
                        
                        <div className="row row-xs align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label text-right mg-b-0">
                              Pesan Pengaduan/Konsolidasi{" "}
                              <span className="tx-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 mg-t-5 mg-md-t-0">
                            <textarea
                              className="form-control"
                              name="pesan"
                              rows="2"
                              onChange={this.handleChange.bind(this, "pesan")}
                              value={this.state.fields["pesan"]}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row row-xs align-items-center mg-b-20">
                          <div className="col-md-12">
                            <label className="form-label mg-b-10">
                              List Persyaratan
                            </label>
                            <div className="table-responsive mb-0 tx-14">
                              <table className="table text-sm table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap" id="tablepersyaratan1">
                                <thead>
                                  <tr>
                                    <th width="15px" className="tx-center">
                                      NO
                                    </th>
                                    <th
                                      style={{ width: "100px" }}
                                      className="tx-center"
                                    >
                                      PERSYARATAN
                                    </th>
                                    <th className="tx-center">UPLOAD BARU</th>
                                   
                                   
                                    <th
                                      style={{ width: "40px" }}
                                      className="tx-center"
                                    >
                                      PILIH
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.errors === false ? (
                                    this.state.list_persyaratan.map(
                                      (data_persyaratan, i) => {
                                        return (
                                          <ComPersyaratan
                                            key={data_persyaratan.id}
                                            nom={i + 1}
                                            cek={window.location.href.split("?")[1]}
                                            data={data_persyaratan}
                                            changefile={this.handleFile}
                                            modal={this.showModal}
                                            previewImg={
                                              this.state.imagePreviewUrl
                                            }
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row row-xs tx-center align-items-center mg-b-20">
                          <div className="col-md-12">
                            <button
                              type="Submit"
                              disabled={dsbled}
                              className="btn btn-main-primary pd-x-20  mg-r-5 mg-t-5"
                            >
                              {this.loadingAction()}
                              <i className="fas fa-save"></i> {htmlbtn}
                            </button>
                           
                            <button
                              type="button"
                              className="btn btn-light pd-x-20 mg-r-5 mg-t-5"
                              onClick={this.cancelForm}
                            >
                              <i className="fas fa-cancel"></i> Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          style={{ zIndex: 2000 }}
          size="lg"
          show={this.state.show}
          onHide={this.cancelModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            List Persyaratan Yang sudah di Upload
            <button
              onClick={this.cancelModal}
              type="button"
              className="close"
              aria-label="Close"
              data-dismiss="modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <table className="table text-sm table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
              <thead>
                <tr>
                  <th width="15px" className="tx-center">
                    NO
                  </th>
                  <th style={{ minWidth: "300px" }} className="tx-center">
                    PERSYARATAN
                  </th>
                  <th style={{ width: "40px" }} className="tx-center">
                    DOWNLOAD
                  </th>
                  <th style={{ width: "40px" }} className="tx-center">
                    PILIH
                  </th>
                </tr>
              </thead>
              <tbody>
              {this.state.errors === false ? (
                  this.state.list_persyaratanktp.map((data_persyaratanktp, i) => {
                    return (
                      <ComPersyaratanktp
                        key={data_persyaratanktp.id}
                        nom={i + 1}
                        data={data_persyaratanktp
                        }
                        modal={this.cancelModal}
                        changefile={this.handleFile2}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-dark"
              onClick={this.cancelModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Main>
    );
  }
}

export default FormPengajuankonsolidasi;
