import React from "react";
//import { Col, Button, Card } from "react-bootstrap";

const ComCetakKK = () => {
  return (
    <tr>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
      <td className="text-left"></td>
    </tr>
  );
};

export default ComCetakKK;
