import React from "react";
import { Link } from "react-router-dom";

const Footer = () =>{
    return (
      <div>
        <div className="main-footer ht-40">
          <div className="container-fluid pd-t-0-f ht-100p">
            <span>
              Copyright © 2021 Capilciamis. Designed by 
              <Link to={{ pathname: "https://zai.web.id/" }} target="_blank" > Zai.web.id</Link> All rights reserved.
            </span>
          </div>
        </div>
      </div>
    )
}

export default Footer;