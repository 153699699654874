import React, { Component } from "react";
import {  Redirect,Link } from "react-router-dom";
//import ComDaftar from "./ComDaftar";
import Main from "../../layout/Main";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PagePendaftaran extends Component {
  constructor() {
    super();
    this.state = {
      btnsimpan:false,
      action1:null,
      fields: {
        nokk: "",
        nama_lgkp:"",
        nik: "",
        pwd: "",
        email: "",
        nohp: "",
        password:"",
        password_confirm:""
      },
      errors: {},
      notif: null,
      notif2:null
    };
  }
  notif = () => {
    if (this.state.notif === true) {
      return(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <strong>{this.state.notif2}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      ); 
    } else if (this.state.notif === false) {
      return (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Pendaftaran Gagal !</strong> {this.state.notif2}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );  
    } else {
      <></>;
    }
  };
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //for huruf match(/^[a-zA-Z]+$/)

    //NIK
    if (!fields["nik"]) {
      formIsValid = false;
      errors["nik"] = "Cannot be empty";
    }

    if (typeof fields["nik"] !== "undefined") {
      if (!fields["nik"].match(/^-?[0-9]\d*\.?\d*$/)) {
        formIsValid = false;
        errors["nik"] = "Only Number";
      }
    }
    //NOKK
    if (!fields["nokk"]) {
      formIsValid = false;
      errors["nokk"] = "Cannot be empty";
    }

    if (typeof fields["nokk"] !== "undefined") {
      if (!fields["nokk"].match(/^-?[0-9]\d*\.?\d*$/)) {
        formIsValid = false;
        errors["nokk"] = "Only Number";
      }
    }
    //NOHP
    if (!fields["nohp"]) {
      formIsValid = false;
      errors["nohp"] = "Cannot be empty";
    }

    if (typeof fields["nohp"] !== "undefined") {
      if (!fields["nohp"].match(/^-?[0-9]\d*\.?\d*$/)) {
        formIsValid = false;
        errors["nohp"] = "Only Number";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    //PASSWORD
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    }
    if (!fields["password_confirm"]) {
      formIsValid = false;
      errors["password_confirm"] = "Cannot be empty";
    }

    if (
      typeof fields["password"] !== "undefined" &&
      typeof fields["confirm_password"] !== "undefined"
    ) {
      if (fields["password"] !== fields["confirm_password"]) {
        formIsValid = false;
        errors["password_confirm"] = "Passwords don't match.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();
    console.log(this.state.fields);
    let fields = this.state.fields;
    this.setState({
      btnsimpan:true
    });
      fetch("lumen7/public/daftarakun", {
        method: "POST",
        mode: "cors",
        body: new FormData(e.target),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.kode === "00") {
         
            this.setState({
              sessionToken: JSON.stringify(data),
              action1:"11",
              loadingAct: true,
              notif: true,
              notif2: data.pesan,
              btnsimpan:false
            });
          } else {
            this.setState({
              action1:null,
              resLogin: data.kode,
              loadingAct: true,
              notif: false,
              notif2: data.pesan,
              btnsimpan:false
            });
          }
          //loading
        })
        .catch((error) => {
          this.setState({
            errors: true,
          });
        });
   
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
  const { action1,btnsimpan} = this.state;
    let htmlbtn='Simpan';
    let dsbled='';
  
     if (btnsimpan === true) {
      htmlbtn="...Loading";
       dsbled="disabled";
    }
    if (action1 === "11") {
      return <Redirect to="/aktivasiakunwa" />;
    }
    return (
      <Main>
        <div>
          <div className="breadcrumb-header justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Pendaftaran Akun
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="main-content-body min-body">
            <div className="row row-sm mt-20">
              <div className="col-md-6 offset-md-3 mg-md-t-0">
                <div className="card">
                  <div className="card-body">
                    <div className="main-content-label mg-b-5">
                      Form Pendaftaran Akun
                    </div>
                    <p className="mg-b-20">
                      Silahkan untuk mengisi form pendaftaran di bawah ini untuk
                      mendapatkan akun.
                    </p>
                    <div className="row row-sm mt-22">
                      <div className="col-md-12">
                        {this.notif()}
                        {/* BEGIN FORM*/}
                        <form
                          onSubmit={this.contactSubmit.bind(this)}
                          id="frmpendaftaran"
                          className="form-horizontal mb-5 "
                          noValidate="novalidate"
                          className="form-horizontal mb-5 contactform"
                          name="contactform"
                        >
                          <div className="form-body">
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  NIK <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="NIK"
                                  type="text"
                                  name="nik"
                                  maxlength="16"
                                  minlength="15"
                                  onChange={this.handleChange.bind(this, "nik")}
                                  value={this.state.fields["nik"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["nik"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  Nama <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="Nama"
                                  type="text"
                                  name="nama_lgkp"
                                  maxlength="16"
                                  minlength="15"
                                  onChange={this.handleChange.bind(this, "nama_lgkp")}
                                  value={this.state.fields["nama_lgkp"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["nama_lgkp"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  NO KK <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="No KK"
                                  type="text"
                                  name="nokk"
                                  maxlength="18"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "nokk"
                                  )}
                                  value={this.state.fields["nokk"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["nokk"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  Email <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "email"
                                  )}
                                  value={this.state.fields["email"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["email"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  NO HP{" "}
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="No HP"
                                  type="text"
                                  name="nohp"
                                  maxlength="18"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "nohp"
                                  )}
                                  value={this.state.fields["nohp"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["nohp"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  Pasword{" "}
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="Password"
                                  type="password"
                                  name="password"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password"
                                  )}
                                  value={this.state.fields["password"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["password"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  Ulangi Pasword
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="Password Ulangi"
                                  type="password"
                                  name="password_confirm"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password_confirm"
                                  )}
                                  value={this.state.fields["password_confirm"]}
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.errors["password_confirm"]}
                                </span>
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4"></div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  type="checkbox"
                                  className="float-left mt-1"
                                  name="cekplus"
                                />
                                <p className="text-left ml-4">
                                  Saya menyatakan data yang dikirim adalah data
                                  benar dan jika dikemudian hari terjadi masalah
                                  hukum maka saya siap mempertanggung
                                  jawabkannya.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="form-actions">
                            <div className="row">
                              <div className="col-md-6 offset-md-4">
                                <button
                                  type="Submit"
                                  disabled={dsbled}
                                  className="btn btn-main-primary pd-x-30 mg-r-5 mg-t-5"
                                >
                                  {htmlbtn}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-dark pd-x-30 mg-t-5"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* END FORM*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PagePendaftaran;
