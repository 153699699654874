import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      usersmp: "ttdatakeluarga",
      pwd: "",
      nik: "",
      nohp:"",
      toDirect: false,
      loadingAct: false,
      errors: false,
      notif:null,
	  notif2:null
    };
  }

  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );

  notif = () => {
    if(this.state.notif === true){
      return(<div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>{this.state.notif2} !</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else if(this.state.notif === false){
      return(<div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Login </strong>  {this.state.notif2}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else{
      <></>
    }
    
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handleDirect = (e) => {
  //   if (this.state.resLogin === "00") {
  //     this.setState({
  //       toDirect: true,
  //     });
  //   }
  // };

  handleSubmit = (e) => {
    fetch("lumen7/public/resetakun", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        nohp: this.state.nohp,
        nik: this.state.nik,
		title:"ok123"
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          localStorage.setItem("login_public", JSON.stringify(data));
          this.setState({
            sessionToken: JSON.stringify(data),
            resLogin: JSON.parse(localStorage.getItem("login_public")).kode,
            loadingAct: false,
            notif:true,
			notif2:data.pesan,
          });
        } else {
          this.setState({
            resLogin: data.kode,
            loadingAct: false,
            notif:false,
			notif2:data.pesan,
          });
        }
        //loading
       
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    e.preventDefault();
  };

  componentDidMount(){
    if (this.state.resLogin === "00") {
      setTimeout(() => {
        this.setState({
          toDirect: true,
        })
      }, 500)
    }
  }


  render() {
   
    
    return (
      <Main>
        <div>
          <div className="breadcrumb-header justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Login
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="main-content-body min-body">
            <div className="row row-sm mt-20">
              <div className="col-md-6 offset-md-3 mg-md-t-0">
                <div className="card">
                  <div className="card-body">
                    <div className="main-content-label mg-b-5">Form reset</div>
                    <p className="mg-b-20">MASUKAN NIK DAN NO HP.</p>
                    <div className="row row-sm mt-20">
                      <div className="col-md-12">
                        {this.notif()}
                        {/* BEGIN FORM*/}
                        <form
                          onSubmit={this.handleSubmit}
                          id="frmlogin"
                          className="form-horizontal mb-5"
                          noValidate="novalidate"
                        >
                          <div className="form-body">
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  NIK{" "}
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="NIK"
                                  type="text"
                                  name="nik"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  No WA{" "}
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                                <input
                                  className="form-control"
                                  placeholder="nohp"
                                  type="text"
                                  name="nohp"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-actions">
                            <div className="row">
                              <div className="col-md-6 offset-md-4">
                                <button
                                  type="submit"
                                  className="btn btn-main-primary mg-r-5 mg-t-5"
                                >
                                  {this.loadingAction()} Login
                                </button>

                                <div className="main-signin-footer mt-3 mg-t-5">
                                  {/* <p>
                                    <a href="page-signup.html">
                                      Forgot password?
                                    </a>
                                  </p> */}
                                  <p>
                                    Dont have an account?{" "}
                                    <Link to="/pendaftaran">
                                      Create an Account
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/*END FORM*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageReset;
