import React from "react";
import { Link } from "react-router-dom";
//import { Col, Button, Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

const ComPersyaratan = (props) => {

  
  return (
    <tr>
      <td className="text-left">{props.nom}</td>
      <td className="text-left">{props.data.persyaratan}</td>
      <td>
        <input
          id={"files-b-" + props.data.id}
          name={"files-b-" + props.data.id}
          className="form-control 11"
          type="hidden"
        />
        <input
          id={"files-" + props.data.id}
          name={"files-" + props.data.id}
          className="form-control 22"
          type="file"
          onChange={(e) => props.changefile(e, props)}
        />
      </td>
    
        
      <td className="tx-center">
      {(() => {
          if (props.data.nfile != "") {
            return (
              <a
                href={props.data.nfile}
                target="_blank"
                
                className=" 12"
              >
                Lihat
              </a>
            );
          }else{
            return(
              <a class="12" target="_blank"></a>
            );
          }

         
        })()}
       
        <Button  className="btn btn-main-primary btn-sm pd-x-20  mg-r-5 mg-t-5" id={props.data.id} onClick={(e) => props.modal(props.data.id)}>
          Pilih 
        </Button>
      </td>
    </tr>
  );
};

export default ComPersyaratan;
