import React, { Component } from "react";
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import $ from "jquery";

class Menu extends Component {
  componentDidMount() {
    //toogle
    $(".horizontal-navtoggle").on("click", function () {
      $("body").toggleClass("active");
    });
    $(".horizontal-overlapbg").on("click", function () {
      $("body").removeClass("active");
    });
    //seacrh
    var res = $(".navbar-form").find(":reset");
    res.on("click", function () {
      $(".navbar-form").removeClass("active");
    });
  }
  render() {
    return (
      <div>
        
        <div className="sticky mt-20">
          <div className="horizontal-main hor-menu clearfix side-header">
            <div className="horizontal-mainwrapper container clearfix">
              <nav className="horizontalMenu clearfix">
                <ul className="horizontalMenu-list">
                  <li >
                    <LinkContainer to="/" exact title="Home">
                      <Nav.Link>
                        <i className="fe fe-home" /> Home
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/pelayanan" title="Pelayanan">
                      <Nav.Link>
                        <i className="fe fe-airplay" /> Pelayanan
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/pendaftaran" title="Pendaftaran">
                      <Nav.Link>
                        <i className="fe fe-file" /> Pendaftaran
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/persyaratan" title="Persyaratan">
                      <Nav.Link>
                        <i className="fe fe-list" /> Persyaratan
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/formulir" title="Formulir">
                      <Nav.Link>
                        <i className="fe fe-download" /> Formulir
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li >
                    <LinkContainer to="/login" title="Login">
                      <Nav.Link>
                        <i className="fe fe-log-in" /> Login
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  {/* <li >
                      <a href="#" className="sub-icon">
                      <i className="fe fe-layers" /> Elements
                      <i className="fe fe-chevron-down horizontal-icon" />
                      </a>
                      <div className="horizontal-megamenu clearfix">
                      <div className="container">
                          <div className="mega-menubg hor-mega-menu">
                          <div className="row">
                              <div className="col-lg-3 col-md-12 col-xs-12 link-list">
                              <ul>
                                  <li>
                                  <h3 className="fs-14 font-weight-bold mb-1 mt-2">
                                      Elements
                                  </h3>
                                  </li>
                                  <li >
                                  <a href="alerts.html" className="slide-item">
                                      Alerts
                                  </a>
                                  </li>
                              </ul>
                              </div>
                              <div className="col-lg-3 col-md-12 col-xs-12 link-list">
                              <ul>
                                  <li >
                                  <a href="navigation.html" className="slide-item">
                                      Navigation
                                  </a>
                                  </li>
                                  <li >
                                  <a href="pagination.html" className="slide-item">
                                      Pagination
                                  </a>
                                  </li>
                              </ul>
                              </div>
                              <div className="col-lg-3 col-md-12 col-xs-12 link-list">
                              <ul>
                                  <li>
                                  <h3 className="fs-14 font-weight-bold mb-1 mt-2">
                                      Apps
                                  </h3>
                                  </li>
                                  <li >
                                  <a href="cards.html" className="slide-item">
                                      Cards
                                  </a>
                                  </li>
                              </ul>
                              </div>
                              <div className="col-lg-3 col-md-12 col-xs-12 link-list">
                              <ul>
                                  <li>
                                  <h3 className="fs-14 font-weight-bold mb-1 mt-2">
                                      Icons{" "}
                                      <span className="badge badge-success ">
                                      New
                                      </span>
                                  </h3>
                                  </li>
                                  <li >
                                  <a href="icons.html" className="slide-item">
                                      Icons
                                  </a>
                                  </li>
                              </ul>
                              </div>
                          </div>
                          </div>
                      </div>
                      </div>
                  </li>
                   */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
