import React, { Component } from "react";
import { Link } from "react-router-dom";
import ComService from "../pelayanan/ComService";
//import ComRekap from "./ComRekap";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageHome extends Component {
  // state = {
  //   rekap_a: [],
  //   rekap_b: [],
  //   rekap_c: []
  // };
  
  // getRekapA = () => {
  //   axios
  //     .get("http://localhost:3004/rekap_satu?_sort=id&_order=asc")
  //     .then((res) => {
  //       this.setState({
  //         rekap_a: res.data,
  //       });
  //     });
  // };
  // getRekapB = () => {
  //   axios
  //     .get("http://localhost:3004/rekap_dua?_sort=id&_order=asc")
  //     .then((res) => {
  //       this.setState({
  //         rekap_b: res.data,
  //       });
  //     });
  // };
  // getRekapC = () => {
  //   axios
  //     .get("http://localhost:3004/rekap_tiga?_sort=id&_order=asc")
  //     .then((res) => {
  //       this.setState({
  //         rekap_c: res.data,
  //       });
  //     });
  // };

  // componentDidMount() {
  //   // this.getRekapA();
  //   // this.getRekapB();
  //   // this.getRekapC();
  // }

  render() {
    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <h5 className="content-title mb-2 mt-22">
              Selamat Datang di Pelayanan Online Disdukcapil Kab.Ciamis
            </h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to="/">Home</Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img src={"ajakan.png"} className="img-responsive mb-3" alt="iklan" />
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="alert alert-light bd" role="alert">
                <span className="alert-inner--icon">
                  <i className="ti-bell text-primary" />
                </span>
                <span className="alert-inner--text text-info">
                  <strong> Info!</strong>
                  <br /> Gunakan No HP dan Email AKTIF saat mendaftar untuk
                  pengiriman hasil permohonan (Akta Kelahiran, Surat Pindah
                  Datang/Keluar, Perubahan KK). JAM PELAYANAN ONLINE, hari
                  senin-kamis (08.00-14.00WIB) hari jumat (08.00-13.30WIB),
                  Pengajuan Akta Kelahiran secara ONLINE untuk semua usia.!
                </span>
              </div>
            </div>
          </div>
          <ComService />
          <div className="row row-sm mt-22">
            <div className="col-md-4 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Pencetakan Akte Kelahiran 2019
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th>Bulan</th>
                          <th>Jumlah</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {this.state.rekap_a.map((rekap_a) => {
                          return (
                            <ComRekap
                              key={rekap_a.id}
                              data={rekap_a}
                            />
                          );
                        })} */}
                        <tr className="bg-info tx-white">
                          <td className="text-right">Jumlah</td>
                          <td className="text-right">
                            <p className="d-inline-block font-weight-semibold mb-0">
                              0
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Pencetakan Kartu Keluarga 2020
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th>Bulan</th>
                          <th>Jumlah</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {this.state.rekap_b.map((rekap_b) => {
                          return (
                            <ComRekap
                              key={rekap_b.id}
                              data={rekap_b}
                            />
                          );
                        })} */}
                        <tr className="bg-info tx-white">
                          <td className="text-right">Jumlah</td>
                          <td className="text-right">
                            <p className="d-inline-block font-weight-semibold mb-0">
                              0
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Pindah 2020
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th>Bulan</th>
                          <th>Jumlah</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {this.state.rekap_c.map((rekap_c) => {
                          return (
                            <ComRekap
                              key={rekap_c.id}
                              data={rekap_c}
                            />
                          );
                        })} */}
                        <tr className="bg-info tx-white">
                          <td className="text-right">Jumlah</td>
                          <td className="text-right">
                            <p className="d-inline-block font-weight-semibold mb-0">
                              0
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </Main>
    );
  }
}

export default PageHome;
