import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionToken: localStorage.getItem("login_public"),
      out: false,
    };
  }

  //logout
  logout = () => {
    this.setState({
      sessionToken: "",
      out: true,
    });
    localStorage.clear();
  };

  

  render() {
    if (this.state.out === true) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <div className="main-header nav nav-item hor-header ht-90">
          <div className="container">
            <div className="main-header-left ">
              <Link
                to="#"
                className="animated-arrow hor-toggle horizontal-navtoggle"
              >
                <span />
              </Link>
              {/* sidebar-toggle*/}
              <Link to="/" className="header-brand">
                <img src={"mini.png"} className="logo-new" alt="Logo" />
                <div className="he text-white tx-bold">
                  DINAS KEPENDUDUKAN DAN CATATAN SIPIL
                  <br />
                  KABUPATEN CIAMIS
                  <br />
                </div>
              </Link>
            </div>
            {/* search */}
            <div className="main-header-right">
              <div
                className="nav nav-item nav-link"
                id="bs-example-navbar-collapse-1"
              >
                
              </div>
              <div className="nav nav-item  navbar-nav-right ml-auto">
                <div className="nav-item full-screen fullscreen-button">
                  <Link to="#" className="new nav-link full-screen-link">
                    <i className="fe fe-maximize" />
                  </Link>
                </div>
                {this.state.sessionToken ? (
                  <>
                <div className="nav-item main-header-message aktivasi">
                <Link
                  to="#"
                  className="new nav-link "
                  title="Aktivasi"
                >
                  <i className="fe fe-user-check"></i>
                </Link>
                </div>
                <div className="nav-item main-header-message lupa_password">
                <Link
                  to="#"
                  className="new nav-link "
                  title="Lupa Password"
                >
                  <i className="fe fe-lock"></i>
                </Link>
                </div>
                <div className="nav-item main-header-message">
                  <Link
                    to="#"
                    className="new nav-link"
                    title="Logout"
                    onClick={this.logout}
                  >
                    <i className="fe fe-log-out"></i>
                  </Link>
                </div>
                </>
                ) : (
                  <>
                  <div className="nav-item main-header-message aktivasi">
                  <Link
                   to="/aktivasiakunwa" 
                    className="new nav-link "
                    title="Aktivasi"
                  >
                    <i className="fe fe-user-check"></i>
                  </Link>
                  </div>
                  <div className="nav-item main-header-message lupa_password">
                  <Link
                    to="/resetakun"
                    className="new nav-link "
                    title="Lupa Password"
                  >
                    <i className="fe fe-lock"></i>
                  </Link>
                  </div>
                 
                  </>
                )}
              </div>
            </div>
          </div>

          
        </div>
      </div>
    );
  }
}

export default Header;
