import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import {
  PageHome,
  PagePendaftaran,
  PageVerifikasi,
  PageLogin,
  PagePersyaratan,
  PageFormulir,
  PagePelayanan,
  PageAnggota,
  PageCetakKK,
  PageCetakKKPindah,
  PageCetakAkte,
  PagePengajuan,
  PageReset,
  PageResetvalidasi,
  PageResetaktivasi
} from "./pages";

import FormPengajuanKK from "./pages/pengajuan/FormPengajuanKK";
import FormPengajuanCetakKIA from "./pages/pengajuan/FormPengajuanCetakKIA";
import FormPengajuanAkteKelahiran from "./pages/pengajuan/FormPengajuanAkteKelahiran";
import FormPengajuanAkteKematian from "./pages/pengajuan/FormPengajuanAkteKematian";
import FormPengajuanAktePerkawinan from "./pages/pengajuan/FormPengajuanAktePerkawinan";
import FormPengajuanAktePerceraian from "./pages/pengajuan/FormPengajuanAktePerceraian";
import FormPengajuanAkteHilang from "./pages/pengajuan/FormPengajuanAkteHilang";
import FormPengajuanPerubahanData from "./pages/pengajuan/FormPengajuanPerubahanData";
import FormPengajuanKedatangan from "./pages/pengajuan/FormPengajuanKedatangan";
import FormPengajuankonsolidasi from "./pages/pengajuan/FormPengajuankonsolidasi";
import FormPengajuanPerpindahan from "./pages/pengajuan/FormPengajuanPerpindahan";


class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={PageHome}>
          <PageHome />
        </Route>
        <Route path="/pelayanan" component={PagePelayanan}>
          <PagePelayanan />
        </Route>
        <Route path="/pendaftaran" component={PagePendaftaran}>
          <PagePendaftaran />
        </Route>
        <Route path="/aktivasiakun" component={PageVerifikasi}>
          <PageVerifikasi />
        </Route>
        <Route path="/aktivasiakunwa" component={PageResetaktivasi}>
          <PageResetaktivasi />
        </Route>
        <Route path="/resetakun" component={PageReset}>
          <PageReset />
        </Route>
        <Route path="/validasiresetakun" component={PageResetvalidasi}>
          <PageResetvalidasi />
        </Route>
        <Route path="/persyaratan" component={PagePersyaratan}>
          <PagePersyaratan />
        </Route>
        <Route path="/formulir" component={PageFormulir}>
          <PageFormulir />
        </Route>
        <Route path="/login" component={PageLogin}>
          <PageLogin />
        </Route>

        <Route path="/anggota" component={PageAnggota}>
          <PageAnggota />
        </Route>
        <Route path="/dokumentte" component={PageCetakKK}>
          <PageCetakKK />
        </Route>
        <Route path="/dokumenpindah" component={PageCetakKKPindah}>
          <PageCetakKKPindah />
        </Route>
        <Route path="/dokumenakte" component={PageCetakAkte}>
          <PageCetakAkte />
        </Route>
        <Route path="/pengajuan" component={PagePengajuan}>
          <PagePengajuan />
        </Route>

        <Route path="/pengajuan_kk" component={FormPengajuanKK}>
          <FormPengajuanKK />
        </Route>
        <Route path="/pengajuan_cetakkia" component={FormPengajuanCetakKIA}>
          <FormPengajuanCetakKIA />
        </Route>
        <Route
          path="/pengajuan_aktekelahiran"
          component={FormPengajuanAkteKelahiran}
        >
          <FormPengajuanAkteKelahiran />
        </Route>
        <Route
          path="/pengajuan_aktekematian"
          component={FormPengajuanAkteKematian}
        >
          <FormPengajuanAkteKematian />
        </Route>
        <Route
          path="/pengajuan_akteperkawinan"
          component={FormPengajuanAktePerkawinan}
        >
          <FormPengajuanAktePerkawinan />
        </Route>
        <Route
          path="/pengajuan_akteperceraian"
          component={FormPengajuanAktePerceraian}
        >
          <FormPengajuanAktePerceraian />
        </Route>
        <Route path="/pengajuan_aktehilang" component={FormPengajuanAkteHilang}>
          <FormPengajuanAkteHilang />
        </Route>
        <Route
          path="/pengajuan_perubahandata"
          component={FormPengajuanPerubahanData}
        >
          <FormPengajuanPerubahanData />
        </Route>
        <Route
          path="/konsolidasi"
          component={FormPengajuankonsolidasi}
        >
          <FormPengajuankonsolidasi />
        </Route>
        <Route path="/pengajuan_kedatangan" component={FormPengajuanKedatangan}>
          <FormPengajuanKedatangan />
        </Route>
        <Route path="/pengajuan_perpindahan" component={FormPengajuanPerpindahan}>
          <FormPengajuanPerpindahan />
        </Route>
      </Switch>
    );
  }
}

export default Routes;
