import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import ComCetakKK from "./ComCetakKK";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageCetakKK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
    };
  }
  render() {
    if (this.state.resLogin !== "00") {
      return <Redirect to="/login" />;
    }
    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Cetak KK
                </li>
              </ol>
            </nav>
          </div>
          {this.state.resLogin==='00' ? (
            <div className="d-flex">
              <div className="d-flex right-page">
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NAMA LENGKAP</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].nama}
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center mr-3">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label ">NIK</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].nik}
                    </span>
                  </div>
                </div>
                <div className="info-user d-flex justify-content-center">
                  <div className="ml-3 mt-2">
                    <span className="d-block">
                      <span className="label">NO KK</span>
                    </span>
                    <span className="value">
                      {JSON.parse(localStorage.getItem("login_public")).data[0].no_kk}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm mt-20">
            <div className="col-md-12 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      DAFTAR CETAK DOKUMEN KK
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                    <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th width="30px">NO</th>
                          <th>NO KK</th>
                          <th>DUSUN</th>
                          <th>KELURAHAN</th>
                          <th>KECAMATAN</th>
                          <th>KABUPATEN</th>
                          <th>TANGGAL</th>
                          <th>CETAK</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ComCetakKK/>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageCetakKK;
