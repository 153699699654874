import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Main from "../../layout/Main";
import $ from 'jquery';
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageResetaktivasi extends Component {
  constructor(props) {
	
    super(props);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const foo = params.get('kode');
    this.state = {
      //resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      usersmp: "ttdatakeluarga",
      pwd: "",
      nik: "",
      kodeotp:"",
      toDirect: false,
      loadingAct: false,
      errors: false,
      notif:null,
	  notif2:null,
	  foo:foo
    };
	

	
	  
  }

  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );

  notif = () => {
    if(this.state.notif === true){
      return(<div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>{this.state.notif2} !</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else if(this.state.notif === false){
      return(<div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Login </strong>  {this.state.notif2}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else{
      <></>
    }
    
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handleDirect = (e) => {
  //   if (this.state.resLogin === "00") {
  //     this.setState({
  //       toDirect: true,
  //     });
  //   }
  // };

  handleSubmit = (e) => {
    $("#btnsimpan").html("...loading");
    $( "#btnsimpan" ).prop( "disabled", true );
    fetch("lumen7/public/validasiakun", {
      method: "POST",
      mode: "cors",
      body: new FormData(e.target)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            loadingAct: false,
            notif:true,
			      notif2:data.pesan
          });
          setTimeout(() => {
            this.loadingAction();
            this.setState({
              resLogin: "11"
            });
           }, 3000);

        } else {
          this.setState({
            resLogin: "00",
            loadingAct: false,
            notif:false,
		    	notif2:data.pesan,
          });
        }
        $("#btnsimpan").html("Submit");
       $( "#btnsimpan" ).prop( "disabled", false );
       
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    e.preventDefault();
  };

  componentDidMount(){
    if (this.state.resLogin === "00") {
      setTimeout(() => {
        this.setState({
          toDirect: true,
        })
      }, 500)
    }
  }


  render() {
    const { resLogin} = this.state;
    let htmlbtn='Simpan';
    let dsbled='';
    if (resLogin === "11") {
      return <Redirect to="/login" />;
    }
    
    return (
      <Main>
         <div>
          
          <div className="main-content-body min-body">
            <div className="row row-sm mt-20">
              <div className="col-md-6 offset-md-3 mg-md-t-0">
                <div className="card">
                  <div className="card-body">
                    <div className="main-content-label mg-b-5">
                      Form AKTIVASI Akun
                    </div>
                    <p className="mg-b-20">
                      Silahkan Masukan NIK dan Kode OTP yang di kirim via Watshap/Email.
                    </p>
                    <br></br>
                    <div className="row row-sm mt-20">
                      <div className="col-md-12">
                        {this.notif()}
                        {/* BEGIN FORM*/}
                        <form
                          onSubmit={this.handleSubmit}
                          id="frmpendaftaran"
                          className="form-horizontal mb-5"
                          noValidate="novalidate"
                          className="form-horizontal mb-5 contactform"
                          name="contactform"
                        >
                          <div className="form-body">
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  NIK <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                              <input
                                  className="form-control"
                                  placeholder="NIK"
                                  type="text"
                                  name="nik"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="row row-xs align-items-center mg-b-20">
                              <div className="col-md-4">
                                <label className="form-label mg-b-0">
                                  KODE OTP <span className="tx-danger">*</span>
                                </label>
                              </div>
                              <div className="col-md-8 mg-t-5 mg-md-t-0">
                              <input
                                  className="form-control"
                                  placeholder="KODE OTP"
                                  type="text"
                                  name="kodeotp"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                           
                          </div>
                          <div className="form-actions">
                            <div className="row">
                              <div className="col-md-6 offset-md-4">
                                <button
                                  type="Submit"
                                  id="btnsimpan"
                                  className="btn btn-main-primary pd-x-30 mg-r-5 mg-t-5"
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-dark pd-x-30 mg-t-5"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* END FORM*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageResetaktivasi;