import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import ComService from "./ComService";
import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PagePelayanan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
    };
  }
  render() {
    if (this.state.resLogin !== "00") {
      return <Redirect to="/login" />;
    }
    return (
      <Main>
        <div className="breadcrumb-header justify-content-between">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pelayanan
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="main-content-body min-body">
          <div className="row row-sm">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="alert alert-light bd" role="alert">
                <span className="alert-inner--icon">
                  <i className="ti-bell text-primary" />
                </span>
                <span className="alert-inner--text text-info">
                  <strong> Info!</strong>
                  <br /> Gunakan No HP dan Email AKTIF saat mendaftar untuk
                  pengiriman hasil permohonan (Akta Kelahiran, Surat Pindah
                  Datang/Keluar, Perubahan KK). JAM PELAYANAN ONLINE, hari
                  senin-kamis (08.00-14.00WIB) hari jumat (08.00-13.30WIB),
                  Pengajuan Akta Kelahiran secara ONLINE untuk semua usia.!
                </span>
              </div>
            </div>
          </div>
          <ComService />
          {this.state.resLogin==='00' ? (
          <div className="row row-sm mt-20">
            <div className="col-md-12 mg-md-t-0">
              <div className="card">
                <div className="card-header tx-medium bd-0 bg-primary">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-2 tx-white">
                      Data Pelayanan
                    </h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive mb-0 tx-14">
                  <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                      <thead>
                        <tr>
                          <th width="30px">NO</th>
                          <th>KODE</th>
                          <th>JENIS</th>
                          <th>PELAYANAN</th>
                          <th>KETERANGAN PEMOHON</th>
                          <th>KETERANGAN ADMIN</th>
                          <th>FILE</th>
                          <th>AKSI</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ):(<></>)}
        </div>
      </Main>
    );
  }
}

export default PagePelayanan;
