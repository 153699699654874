import React, { Component } from "react";

import Header from "./Header";
import Menu from "./Menu";
import MenuAccount from "./MenuAccount";
import Footer from "./Footer";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      loading: true,
    };
  }

  componentWillMount() {
    //loading
    setTimeout(() => {
      this.setState({ loading: false });
    }, 100);
  }
  loading = () => 
    this.state.loading === true ? (
      <div id="global-loader">
        <img
          src="assets/img/loaders/loader-4.svg"
          className="loader-img"
          alt="Loader"
        />
      </div>
    ) : (
      <div></div>
    );
  render() {
    const children = this.props;
    return (
      <div>
        {this.loading()}
        <div className="page">
          <Header />
          {this.state.resLogin==="00" ? <MenuAccount /> : <Menu />}
          <div className="main-content horizontal-content">
            <div className="container">{this.props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Main;
