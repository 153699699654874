import React, { Component } from "react";
import {Link} from "react-router-dom";
import ComFormulir from "./ComFormulir";
import Main from "../../layout/Main";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageFormulir extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_persyaratan: [],
      data_persyaratan: {},
      errors: false,
      resLogin:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
    };
  }
  
  listPersyaratan = () => {
    fetch("lumen7/public/dataformulir", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        id: 11
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            list_persyaratan: data.data,
            data_persyaratan: JSON.stringify(data.data),
          });
        }
        
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount() {
    this.listPersyaratan();
  }
  render() {
    return (
      <Main>
        <div>
          <div className="breadcrumb-header justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Formulir
                  </li>
                </ol>
              </nav>
            </div>
            {this.state.resLogin==='00' ? (
              <div className="d-flex">
                <div className="d-flex right-page">
                  <div className="info-user d-flex justify-content-center mr-3">
                    <div className="ml-3 mt-2">
                      <span className="d-block">
                        <span className="label ">NAMA LENGKAP</span>
                      </span>
                      <span className="value">
                        {JSON.parse(localStorage.getItem("login_public")).data[0].nama}
                      </span>
                    </div>
                  </div>
                  <div className="info-user d-flex justify-content-center mr-3">
                    <div className="ml-3 mt-2">
                      <span className="d-block">
                        <span className="label ">NIK</span>
                      </span>
                      <span className="value">
                        {JSON.parse(localStorage.getItem("login_public")).data[0].nik}
                      </span>
                    </div>
                  </div>
                  <div className="info-user d-flex justify-content-center">
                    <div className="ml-3 mt-2">
                      <span className="d-block">
                        <span className="label">NO KK</span>
                      </span>
                      <span className="value">
                        {JSON.parse(localStorage.getItem("login_public")).data[0].no_kk}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="main-content-body min-body">
            <div className="row row-sm mt-20">
              <div className="col-md-12 mg-md-t-0">
                <div className="card">
                  <div className="card-body">
                    <div className="main-content-label mg-b-5">
                      Daftar Formulir
                    </div>
                    <p className="mg-b-20"></p>
                    <div className="row row-sm mt-22">
                      <div className="col-md-12">
                      <div>
                        <div className="table-responsive mb-0 tx-14">
                          <table className="table table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                            <thead>
                              <tr>
                                <th>Nama Formulir</th>
                                <th>Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.errors === false ? (
                                    this.state.list_persyaratan.map(
                                      (data_persyaratan, i) => {
                                        return (
                                          <ComFormulir
                                          key={data_persyaratan.id}
                                          nom={i + 1}
                                          data={data_persyaratan}
                                           
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                            </tbody>

                          </table>
                        </div>
                      </div>
                      
                               
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default PageFormulir;
